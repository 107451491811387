import { IEnvironment } from '@env/environment.model';

export const environment: IEnvironment = {
  production: true,
  config: {
    logs: {
      // required config
      collector:
        'ZaVnC4dhaV0fSZCNXu-3G97amPMehFdN4fs9W17kxXz47AJYqVBghQtNyqpqqO8rufFihz1elJGEKWi7Vbgqa6dojoJdAMrnrHdwTtVpxPEA7F3ozP9SHQ==',
      // optional config
      endpoint: 'https://collectors.au.sumologic.com/receiver/v1/http/',
      level: 'error',
    },
    layout: {
      renderer: 'https://leapcalc-renderer-us-test.leap.services',
    },
    cache: {
      roleARN: 'arn:aws:iam::438555434192:role/redis-shared-parameterstore-us-east-1',
      sessionName: 'leap365-sirius',
      parameterStoreKey: 'redis-shared-connection',
      awsRegion: 'us-east-1',
    },
    brand: {
      analytics: 'GTM-MD8QMN6',
      id: 'test.leap365.com',
      name: 'Leap',
      leapcalc: 'leap',
      env: 'test',
      region: 'us',
      helpUrl: '//community.leap.us/s/',
      termsUrl: 'https://www.leap.us/terms.html',
      privacyUrl: 'https://www.leap.us/privacy.html',
      brandUrl: 'test.leap365.com',
      bylawyersUrl: 'https://bylawyers-option-leap.leap.co.uk',
      copyrightName: 'LEAP Software Developments Pty. Limited',
    },
    precedent: {
      letterContainerId: '3008',
    },
    support: {
      communityUrl: 'https://leap--community.sandbox.my.site.com/uscommunity/s',
      liveChatUrl: 'https://secure.livechatinc.com/licence/4997331/v2/open_chat.cgi',
      feedbackUrl: 'https://uxform.typeform.com/to',
      feedbackCode: 'XbNsAv',
    },
    oauth: {
      scopes: '*',
      endpoint: 'https://login.test.leap365.com',
      user_info_uri: '/api/userinfo',
      provider_id: 'LEO',
      client_id: '1MM8NUI1N9ZRYJOG',
      uniqueSessionActive: true,
      autoLogoutActive: false,
      userPresence: true,
      backendClientId: 'SNAXWYYI1RRKYGT2',
    },
    automation: {
      uriSchema: 'leapustest',
    },
    keys: {
      pnSubscribe: 'sub-a456f002-0095-11e2-9638-9581afc33ebf',
    },
    apps: {
      glLink: 'https://test-gllinkapp.leapaws.com',
      marketPlace: 'https://test.marketplace.leap.build/apps',
      lawconnect: 'https://lawconnect.test.leap365.com',
      appIds: {
        infotrack: [],
        timesheet: ['f06f7f79-fe07-4052-a3ac-0aba2e363dc1', 'bc4d9b78-b8ea-47b9-b996-8d519e0f8f7c'],
      },
      appFunctions: {
        timesheet: ['8ad9fd1a-7895-4c5c-8810-d7d847934a41'],
      },
    },
    endpoint: {
      account: 'https://account2-api-us-test.leap.services',
      accounting: 'https://api-offexaccounting-test.leapaws.com',
      accountingv2: 'https://acc-api-test.leapaws.com',
      adminLawConnect: 'https://admin.test.lawconnect.com',
      appConsole: 'https://api.test.console.leap.build',
      automation: 'https://office-automation-api-test.leapaws.com',
      bankrec: 'https://bankrec.test.leap365.com',
      bylawyers: 'https://test.bylawyers.com',
      calendar: 'https://superdiary-api-test.leapaws.com',
      cdn: 'https://cdn.leap.com',
      docs: 'https://api-docs-test.leapaws.com',
      docsPublic: 'https://api-docs-public-test.leapaws.com',
      docBuilder: 'https://doc-builder-test.leapaws.com',
      infotrack: 'https://stagesearch.infotrack.com',
      trisearch: 'https://stagesearch.infotrack.com',
      leaponline: 'https://updates.test.leapaws.com/leap-online/public/LEAPWebInstaller.exe',
      myobweb: 'https://myobweb-test.leapaws.com',
      pdfservice: 'https://pdfservice-test.leapaws.com',
      quickbook: 'https://quickbooksweb-test.leapaws.com/',
      reporting: 'https://reporting-test.leapaws.com',
      reportingApi: 'https://reporting-api-test.leapaws.com',
      schema: 'https://schema-api-us-test.leap.services',
      wopi: 'https://office-wopi-api-test.leapaws.com',
      xero: 'https://xeroweb-test.leapaws.com',
      auth: 'https://login.test.leap365.com',
      globalAuth: 'https://auth-test.leap.services',
      options: 'https://acc-options-api-test.leapaws.com',
      options_app: 'https://settings.test.leap365.com',
      leapadin: 'https://leapaddins-api-test.leapaws.com',
      listaddin: 'https://updates-leapaws-com.s3.amazonaws.com/1/test/x64/matteraddin/api-listV3.json',
      notifications: 'https://notificationsapi-test.leapaws.com',
      lcintegrator: 'https://lcintegrator-test.leapaws.com',
      phoenix: 'https://guides.test.bylawyers.com',
      msteams: 'https://teams-test.leapaws.com',
      content: 'https://api-contentgateway-test.leapaws.com',
      docsV2: 'https://api-docs-core.test.leapaws.com',
      leapDesign: 'https://leapdesign.leapaws.com/us/test',
    },
    aws: {
      reportingBucket: 'sirius-transactions-test-leapaws-com',
      region: 'us-east-1',
      paramStorePath: '/officecloud/leap365/',
      accountId: '064865921863',
    },
    ksd: {
      enabled: true,
      configurations: [
        {
          protocol: 'https',
          method: '*',
          domain: `api-docs-test.leapaws.com`,
          path: '/api/*',
        },
      ],
    },
    esignature: {
      enabled: true,
    },
    invoiceSharing: {
      enabled: false,
    },
    featureFlag: {
      client_id: '6655571aaba81f105a9219d3',
    },
    auth0: {
      domain: 'leapauthtest.au.auth0.com',
      clientId: 'FM6QHtgu9Ftwrn5HBs0XKjADVMX74IzV',
    },
  },
};
